import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../environments/environments';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  token$: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor(private http: HttpClient, private router: Router) {}

  onRequestLogin(body: any): Observable<any> {
    return this.http.post<any>(`${environment.CHECKLIST}user/login`, body);
  }

  forgotPassword(body: { email: string }): Observable<any> {
    return this.http.put<any>(
      `${environment.CHECKLIST}user/forgotPassword`,
      body
    );
  }

  logOut(): Observable<any> {
    return of(this.router.navigate(['/']));
  }

  onGetAllBranchs(): Observable<any> {
    return this.http.get<any>(`${environment.CHECKLIST}Branch/GetAll`);
  }
}
