import { Component } from '@angular/core';

@Component({
  selector: 'app-branch-user',
  standalone: true,
  imports: [],
  templateUrl: './branch-user.component.html',
  styleUrl: './branch-user.component.scss'
})
export class BranchUserComponent {

}
