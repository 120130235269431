<div class="c-title">
    <div class="c-title__header">
        <div class="c-title__header--flex">
            <h2 class="c-title__header--title">Gerenciar grupos do <span>Questionário</span></h2>
            <button mat-raised-button
                    class="c-btn"
                    (click)="true">
                        <span>Novo grupo</span>
            </button>
        </div>
        <hr>
    </div>
</div>
<div class="c-content">
    <div cdkDropList class="card-list" (cdkDropListDropped)="drop($event)">
        @for (group of groups; track group;let  i = $index) {
            <div class="card-box" cdkDrag>
                <span class="card-box__align">
                    <span class="material-symbols-rounded"> drag_handle </span> 
                    <span class="card-box__index">{{ i + 1 }}</span> 
                    {{group.name}}
                </span>
                <div class="card-box__actions">
                    <span class="card-box__actions--quizzes">{{group.questions}} questões</span>
                    <span class="material-symbols-rounded card-box__actions--icon"> delete </span>
                </div>
            </div>
        }
    </div>
</div>