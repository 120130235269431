import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-quizzes-group',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    CdkDropList, 
    CdkDrag
  ],
  templateUrl: './quizzes-group.component.html',
  styleUrl: './quizzes-group.component.scss'
})
export class QuizzesGroupComponent {
  groups = [
    { name: 'Grupo I - testes grupos', id: '123', questions: '10'},
    { name: 'Grupo II - testes grupos', id: '123', questions: '10'},
    { name: 'Grupo III - testes grupos', id: '123', questions: '10'},
    { name: 'Grupo IV - testes grupos', id: '123', questions: '10'},
    { name: 'Grupo V - testes grupos', id: '123', questions: '10'},
    { name: 'Grupo VI - testes grupos', id: '123', questions: '10'},
    { name: 'Grupo VII - testes grupos', id: '123', questions: '10'},
    { name: 'Grupo VIII - testes grupos', id: '123', questions: '10'},
    { name: 'Grupo IX - testes grupos', id: '123', questions: '10'},
  ];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.groups, event.previousIndex, event.currentIndex);
  }

}
