import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavbarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  blacklistRoutes: string[] = ['login', ''];
  showNavbar = true
  showMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  
  constructor(private router: Router, 
              private route: ActivatedRoute,
              private themeService: ThemeService) { }
  
  ngOnInit() {
    this.onMonitoringRouter();
    this.themeService.toggleTheme();
  }

  onMonitoringRouter(): void{
    this.router.events.subscribe(() => {
      const firstChild = this.route.snapshot.firstChild;
      if (firstChild && firstChild.routeConfig) {
        const currentRoute = firstChild.routeConfig.path;
        if (typeof currentRoute === 'string') {
          this.showNavbar = !this.blacklistRoutes.includes(currentRoute);
        } else {
          this.showNavbar = true;
        }
      } else {
        this.showNavbar = true;
      }
    });
  }  

  onToggleMenu(event: any): void{
    this.showMenu.next(event);
  }

  
  toggleTheme() {
    this.themeService.toggleTheme();
  }
  
  
}
