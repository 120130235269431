import { Component } from '@angular/core';

@Component({
  selector: 'app-collaborators',
  standalone: true,
  imports: [],
  templateUrl: './collaborators.component.html',
  styleUrl: './collaborators.component.scss'
})
export class CollaboratorsComponent {

}
