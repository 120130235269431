<div class="c-title">
    <div class="c-title__header">
        <div class="c-title__header--flex">
            <h2 class="c-title__header--title">Gerenciamento de <span>Questionários</span></h2>
            <button mat-raised-button
                    class="c-btn"
                    (click)="onOpenAdd()">
                        <span>Novo questionário</span>
            </button>
        </div>
        <hr>
    </div>
    <div class="c-title__content">
        <app-table-generic
            [listDataSource]="dataSource"
            [columns]="tableColumns"
            [columnMapping]="namesColumnMapping"
            [isLoading]="isLoad"
            (emmitDetails)="onActionEmmit($event)"
        ></app-table-generic>
    </div>
</div>