
<div class="c-home">
    <div class="c-home__header">
        <h1>Bem vindo de volta!</h1>
        <hr>
        <p>Como podemos lhe ajudar hoje?</p>
    </div>
    <div class="c-home__content">
        @for (menu of menuList; track $index) {
            <div class="c-card-home">
                <div class="c-card-home__header">
                    <span class="material-symbols-rounded c-card-home__icon"> {{ menu.icon }} </span>
                    <h2 class="c-card-home__title">{{ menu.name }}</h2>
                </div>
                <mat-list role="list" class="c-card-home__list">
                @for (link of menu.links; track $index) {
                    <mat-list-item class="c-card-home__list--item" (click)="onRedirect(link.url)">
                        <span>{{ link.name }}</span>
                        <span class="material-symbols-rounded c-card-home__list--icon"> arrow_forward </span>
                    </mat-list-item>
                }
            </mat-list>
            </div>
        }
    </div>    
</div>