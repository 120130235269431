import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../core/services/auth.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-lost-password',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    DialogModule,
  ],
  templateUrl: './lost-password.component.html',
  styleUrl: './lost-password.component.scss',
})
export class LostPasswordComponent {
  form: FormGroup;
  mailDisplay = '';
  confirmationCard = false;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    @Inject(DIALOG_DATA) public data: any,
    public dialogRef: DialogRef
  ) {
    this.form = this.fb.group({});
  }
  ngOnInit(): void {
    this.onBuildForm();
  }

  onBuildForm(): void {
    this.form = this.fb.group({
      mail: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmitChange(): void {
    this.loading = true;

    const body = {
      email: this.form.get('mail')?.value,
    };

    this.authService.forgotPassword(body).subscribe({
      next: () => {
        this.loading = false;
        this.confirmationCard = true;
        this._snackBar.open('E-mail enviado com sucesso', 'fechar');
      },
      error: () => {
        this._snackBar.open(
          'Houve um problema ao tentar solicitar redefinição de senha!',
          'fechar'
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  processEmail(email: string): string {
    const [username, domain] = email.split('@');
    const processedUsername =
      username.slice(0, 3) +
      '*'.repeat(username.length - 5) +
      username.slice(-2);
    return processedUsername + '@' + domain;
  }

  onGoBack(): void {
    this.dialogRef.close();
  }
}
