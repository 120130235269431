<div class="c-modal">
    <div class="c-modal__close">
        <span class="material-symbols-rounded" (click)="onClose()"> close </span>
    </div>
    <div class="c-modal__header">
        <div class="c-modal__header--icon">
            <span class="material-symbols-rounded">{{ data.icon }} </span>
        </div>
        <div class="c-modal__header--title">
            <h1>{{ data.title }}</h1>
            <h3>{{ data.subTitle }}</h3>
        </div>
    </div>
    <div class="c-modal__content">
        <p>{{ data.message }}</p>
        <h3>{{ data.item }}</h3>
    </div>
    <div class="c-modal__footer">
        <button mat-raised-button
                class="c-modal__footer--btn cancel"
                (click)="onClose()">
                    <span>Cancelar</span>
        </button>
        <button mat-raised-button
                class="c-modal__footer--btn"
                (click)="onConfirm()"
                color="primary">
                    <span>Confirmar</span>
        </button>
    </div>
</div>