import { Component } from '@angular/core';

@Component({
  selector: 'app-quizzes-template',
  standalone: true,
  imports: [],
  templateUrl: './quizzes-template.component.html',
  styleUrl: './quizzes-template.component.scss'
})
export class QuizzesTemplateComponent {

}
