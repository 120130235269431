<mat-toolbar class="c-navbar">
    <span class="c-navbar__logo">
        <img src="../../../assets/logo-dark-nav.svg" alt="" srcset="" (click)="onRedirect('/home')">
    </span>
    <div class="c-navbar__actions">
        <button mat-icon-button aria-label="Dark Light Mode selector" (click)="toggleMode()">
            @if(mode !== 'light'){
                <span class="material-symbols-rounded"> dark_mode </span>
            }@else{
                <span class="material-symbols-rounded"> light_mode </span>
            }
        </button>
        <div class="c-profile">
            <div class="c-profile__init">
                <img src="../../../assets/profile-default.png" alt="" srcset="">
            </div>
            <div class="c-profile__content">
                <p><b>{{'Usuário modelo'}}</b></p>
                <small>{{'email modelo'}}</small>
            </div>
            <div class="c-profile__end">
                <span class="material-symbols-rounded"> expand_more </span>
            </div>
        </div>
        <button mat-icon-button (click)="onLogout()">
            <span class="material-symbols-rounded"> logout </span>
        </button>
    </div>
</mat-toolbar>