import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-create-survey',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './create-survey.component.html',
  styleUrl: './create-survey.component.scss'
})
export class CreateSurveyComponent implements OnInit{
  editMode = false;
  title = 'Novo questionário';
  subTitle = 'Preencha os dados paracriar um novo questionário';

  form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateSurveyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.onBuildForm();
    this.onChangeMode();
  }

  onChangeMode(): void{
    this.editMode = this.data.editMode;
    console.log('editMode', this.editMode);
    
    this.title = this.editMode ? 'Edição do questionário' : 'Novo questionário';
    this.subTitle = this.editMode ? 'Altere os dados do questionário' : 'Preencha os dados paracriar um novo questionário';
  }

  onBuildForm(): void{
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  onClose(): void{
    this.dialogRef.close(this.data);
  }

  onSubmit(): void{
    if (this.form.valid) {
      console.log('Formulário enviado:', this.form.value);
    } else {
      console.log('Por favor, preencha todos os campos obrigatórios.');
    }
  }
}
