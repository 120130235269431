<mat-card class="c-card-lost">
    <div class="btn_back" (click)="onGoBack()">
        <span class="material-symbols-rounded"> chevron_left </span>Voltar
    </div>
    <div class="c-lost">
        @if (!confirmationCard) {
        <div class="c-lost__header">
            <span class="material-symbols-rounded icon">password</span>
            <h2>Esqueci minha senha</h2>
            <p>Insira o email para redefini-la.</p>
        </div>
    
        <form [formGroup]="form" class="c-lost__form">
            <mat-form-field appearance="outline">
            <mat-label>E-mail</mat-label>
            <input matInput type="text" formControlName="mail" />
            <mat-error *ngIf="form.get('mail')?.hasError('email') && form.get('mail')?.touched">
                Por favor, informe um e-mail válido.
            </mat-error>
            </mat-form-field>
    
            <button
                mat-raised-button
                class="c-btn-submit"
                color="primary"
                type="submit"
                [disabled]="!form.valid || loading"
                (click)="onSubmitChange()">
                @if (loading) {
                    <div class="c-btn-spinner">
                    <mat-spinner class="c-btn-spinner__spinner"></mat-spinner>
                    <span>Carregando...</span>
                    </div>
                } @else {
                    <span>Enviar</span>
                }
            </button>
        </form>
        } @else {
        <div class="c-lost__header">
            <span class="material-symbols-rounded icon">mark_email_read</span>
            <h2>Verifique seu e-mail</h2>
            <p>O link de recuperação foi enviado para</p>
            <p>{{ mailDisplay }}</p>
        </div>
        }
  </div>
</mat-card>