import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ThemeService } from '../../core/services/theme.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MatToolbarModule, MatIconModule, MatButtonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  mode: string = 'dark';
  showMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  user: any = {};

  isDarkMode = false;

  constructor(private route: Router, private themeService: ThemeService) {}

  ngAfterViewInit(): void {
    // this.onSetUser();
  }

  onRedirect(route: string): void {
    this.route.navigateByUrl(route);
  }

  onSetUser(): void {
    const user = JSON.parse(sessionStorage.getItem('user')!);
    this.user = user;
  }

  toggleMode() {
    this.mode = this.mode === 'dark' ? 'light' : 'dark';
    this.isDarkMode = !this.isDarkMode;
    this.themeService.toggleTheme();
  }

  onLogout(): void {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    this.route.navigate(['/']);
  }
}
