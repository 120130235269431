import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  let router = inject(Router);
  let authService = inject(AuthService)

  return next(req).pipe(
    catchError((error) => {
      if([401, 403].includes(error.status)){
        console.log('Unauthorized request');
        authService.logOut()
        router.navigateByUrl('/')
      }
      console.log(error.message);
      const e = error.message || error.statusText;      
      return throwError(() => error)
    })
  );
};