import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-generic-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './generic-modal.component.html',
  styleUrl: './generic-modal.component.scss'
})
export class GenericModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              public dialogRef: MatDialogRef<GenericModalComponent>) { }

  onClose(): void{
    this.dialogRef.close(this.data);
  }

  onConfirm(): void{
    this.dialogRef.close(this.data);
  }
}
