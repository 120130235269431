import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (req.headers.has('Skip-Interceptor') && req.headers.get('Skip-Interceptor') === 'true') {
    const newRequest = req.clone({ headers: req.headers.delete('Skip-Interceptor') });
    return next(newRequest);
  }

  const token = sessionStorage.getItem('token');

  if (!token) {
    authService.logOut();
    router.navigateByUrl('/');
    return next(req);
  }

  const authReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`),
  });

  return next(authReq);
};
