import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, MatListModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  menuList = [
    {
      name: ' Gerenciar checklist',
      icon: 'fact_check',
      links: [
        { name: 'Atribuir questionários ao usuário', url: '/quizzes-user' },
        { name: 'Atribuir questionários ao modelo', url: '/quizzes-template' },
        { name: 'Atribuir filial ao usuário', url: '/branch-user' },
        { name: 'Gerenciar Questionários', url: '/manage-surveys' },
      ],
    },
    {
      name: 'Gerenciar acesso ao sistema ',
      icon: 'manage_accounts',
      links: [
        { name: 'Colaboradores Madero', url: '/collaborators' },
        { name: 'Acesso Externo', url: '/collaborators' },
      ],
    },
  ];

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.onGetAllBranchs().subscribe({
      next: (response) => {
        console.log(response);
      },
      error: () => {},
    });
  }

  onRedirect(url: string): void {
    this.router.navigateByUrl(url);
  }
}
