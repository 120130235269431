import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private isDarkMode = new BehaviorSubject<boolean>(true);
  isDarkMode$ = this.isDarkMode.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  toggleTheme() {
    this.isDarkMode.next(!this.isDarkMode.value);
    this.updateBodyThemeClass();
  }

  private updateBodyThemeClass() {
    const body = this.document.body;
  body.classList.remove('dark', 'light');
  body.classList.add(this.isDarkMode.value ? 'dark' : 'light');

  const rootElement = this.document.documentElement;

  rootElement.style.removeProperty('--theme-background');
  rootElement.style.removeProperty('--theme-color');

  if (this.isDarkMode.value) {
    rootElement.style.setProperty('--theme-background', '#000000');
    rootElement.style.setProperty('--theme-color', '#ffffff');
  } 
  }
}
