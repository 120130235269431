import { CommonModule, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../core/services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { Dialog } from '@angular/cdk/dialog';
import { LostPasswordComponent } from '../../shared/lost-password/lost-password.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  form!: FormGroup;
  hide: boolean = true;
  loading: boolean = false;
  currentYear = new Date().getFullYear();

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public dialog: Dialog
  ) {}

  ngOnInit(): void {
    this.onBuildForm();
  }

  onBuildForm(): void {
    this.form = this.fb.group({
      mail: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  onSubmit(): void {
    this.loading = true;
    const formResult = this.form.getRawValue();

    const body: any = {
      user: formResult.mail,
      password: formResult.password,
    };

    this.authService.onRequestLogin(body).subscribe({
      next: (user) => {
        this.authService.user$.next(user);
        this.authService.token$.next(user.value.token);
        sessionStorage.setItem('token', user.value.token);
        sessionStorage.setItem('user', JSON.stringify(user));
        this.router.navigate(['/home']);
      },
      error: () => {
        this.loading = false;
        this._snackBar.open(
          'Houve um problema ao tentar fazer o login!',
          'fechar'
        );
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  openDialog() {
    this.dialog.open(LostPasswordComponent, {
      minWidth: '400px',
      data: {},
    });
  }
}
