<div class="c-modal">
    <div class="c-modal__close">
        <span class="material-symbols-rounded" (click)="onClose()"> close </span>
    </div>
    <div class="c-modal__header">
        <div class="c-modal__header--icon">
            <span class="material-symbols-rounded"> quiz </span>
        </div>
        <div class="c-modal__header--title">
            <h1>{{ title }}</h1>
            <h3>{{ subTitle }}</h3>
        </div>
    </div>
    <div class="c-modal__content">
        <form [formGroup]="form">
            <mat-form-field appearance="outline" >
              <mat-label for="title">Título:</mat-label>
              <input matInput type="text" id="title" formControlName="title">
              <mat-error *ngIf="form.get('title')?.hasError('required') && form.get('title')?.touched">
                Titulo é obrigatório.
              </mat-error>
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label for="description">Descrição:</mat-label>
              <textarea matInput id="description" formControlName="description"></textarea>
              <mat-error *ngIf="form.get('description')?.hasError('required') && form.get('description')?.touched">
                Descrição é obrigatória.
              </mat-error>
            </mat-form-field>
          </form>
    </div>
    <div class="c-modal__footer">
        <button mat-raised-button
                class="c-modal__footer--btn cancel"
                (click)="onClose()">
                    <span>Cancelar</span>
        </button>
        <button mat-raised-button
                class="c-modal__footer--btn"
                (click)="onSubmit()"
                [disabled]="!form.valid">
                    <span>Cadastrar</span>
        </button>
    </div>
</div>