import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TableGenericComponent } from '../../shared/table-generic/table-generic.component';
import { GenericTableData } from '../../core/models/generic-table.interface';
import { TableColumnMapping } from '../../core/models/table-mapping.interface';
import { BehaviorSubject } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CreateSurveyComponent } from '../../shared/modals/create-survey/create-survey.component';
import { GenericModalComponent } from '../../shared/modals/generic-modal/generic-modal.component';

@Component({
  selector: 'app-manage-surveys',
  standalone: true,
  imports: [
    CommonModule,
    TableGenericComponent,
    MatButtonModule
  ],
  templateUrl: './manage-surveys.component.html',
  styleUrl: './manage-surveys.component.scss'
})
export class ManageSurveysComponent implements OnInit{
  dataSource: GenericTableData[] = []
  tableColumns: string[] = [
    'questionario',
    'descricao',
    'qtdgrupos',
    'qtdperguntas',
    'info',
    'edit',
    'delete',
  ]
  namesColumnMapping: TableColumnMapping = {
    questionario: 'Questionário',
    descricao: 'Descrição',
    qtdgrupos: 'Qtd. Grupos',
    qtdperguntas: 'Qtd. perguntas',
    info: '',
    edit: '',
    delete: '',
  }
  
  isLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  constructor(private dialog: MatDialog){}

  ngOnInit(): void {
    this.onGetAllSurveys();
  }

  onGetAllSurveys(): void{
    this.dataSource = [
      {
        id: '324523452345-23452345-23542345',
        questionario: 'Treinamento steakhouse 1',
        descricao: 'Treinamento',
        qtdgrupos: '5 grupos',
        qtdperguntas: '15 perguntas',
        info: '',
        edit: '',
        delete: '',
      },
      {
        id: '324523452345-23452345-23542345',
        questionario: 'Treinamento steakhouse 3',
        descricao: 'Treinamento',
        qtdgrupos: '5 grupos',
        qtdperguntas: '15 perguntas',
        info: '',
        edit: '',
        delete: '',
      },
      {
        id: '324523452345-23452345-23542345',
        questionario: 'Treinamento steakhouse 2',
        descricao: 'Treinamento',
        qtdgrupos: '5 grupos',
        qtdperguntas: '15 perguntas',
        info: '',
        edit: '',
        delete: '',
      },
      {
        id: '324523452345-23452345-23542345',
        questionario: 'Treinamento steakhouse 8',
        descricao: 'Treinamento',
        qtdgrupos: '5 grupos',
        qtdperguntas: '15 perguntas',
        info: '',
        edit: '',
        delete: '',
      },
      {
        id: '324523452345-23452345-23542345',
        questionario: 'Treinamento steakhouse 2',
        descricao: 'Treinamento',
        qtdgrupos: '5 grupos',
        qtdperguntas: '15 perguntas',
        info: '',
        edit: '',
        delete: '',
      },
      {
        id: '324523452345-23452345-23542345',
        questionario: 'Treinamento steakhouse 8',
        descricao: 'Treinamento',
        qtdgrupos: '5 grupos',
        qtdperguntas: '15 perguntas',
        info: '',
        edit: '',
        delete: '',
      }
    ]
  }

  onActionEmmit(event: any): void{ 
    switch (event.action) {
      case 'info':
        console.log('Action information!');        
        break;
      case 'edit':
        this.onOpenEdit();     
        break;
      case 'delete':
        this.onOpenRemove(event);      
        break;
    
      default:
        console.log('Action both!'); 
        break;
    }
  }

  onOpenAdd(): void{
    this.dialog
      .open(CreateSurveyComponent, {
        width: '550px',
        data: { editMode: false },
      })
      .afterClosed()
      .subscribe((response) => { console.log('Close: ', response); });
  }

  onOpenEdit(): void{
    this.dialog
      .open(CreateSurveyComponent, {
        width: '550px',
        data: { editMode: true },
      })
      .afterClosed()
      .subscribe((response) => { console.log('Close: ', response); });
  }

  onOpenRemove(survey: any): void{
    console.log('survey', survey);
    
    this.dialog
      .open(GenericModalComponent, {
        width: '450px',
        data: { 
          icon: 'warning', 
          title: 'Excluir Questionário', 
          subTitle: 'confirme a ação de exclusão', 
          message: 'Tem certeza que deseja remover:', 
          item: survey.questionario, 
        },
      })
      .afterClosed()
      .subscribe((response) => { 
        console.log('Close: ', response); 
        console.log('remove: ', survey); 
      });
  }
}
