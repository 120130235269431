import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { QuizzesUserComponent } from './pages/quizzes-user/quizzes-user.component';
import { QuizzesTemplateComponent } from './pages/quizzes-template/quizzes-template.component';
import { BranchUserComponent } from './pages/branch-user/branch-user.component';
import { ManageSurveysComponent } from './pages/manage-surveys/manage-surveys.component';
import { CollaboratorsComponent } from './pages/collaborators/collaborators.component';
import { QuizzesGroupComponent } from './pages/quizzes-group/quizzes-group.component';

export const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
    },
    {
        path: 'home',
        component: HomeComponent,
    },
    {
        path: 'quizzes-user',
        component: QuizzesUserComponent,
    },
    {
        path: 'quizzes-template',
        component: QuizzesTemplateComponent,
    },
    {
        path: 'branch-user',
        component: BranchUserComponent,
    },
    {
        path: 'manage-surveys',
        component: ManageSurveysComponent,
    },
    {
        path: 'manage-surveys/group/:id',
        component: QuizzesGroupComponent,
    },
    {
        path: 'collaborators',
        component: CollaboratorsComponent,
    },
    { path: '**', redirectTo: '' }
];