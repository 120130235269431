@if (activeFilter) {
    <div class="c-filter">
      <mat-form-field class="c-filter--field" appearance="outline">
        <mat-label>Digite para buscar</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. Nome"
          #input
          [(ngModel)]="textSearch"
          #ctrl="ngModel"
        />
        @if (textSearch) {
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="onCleanSearch()"
          >
            <mat-icon>close</mat-icon>
          </button>
        } @else {
          <button matSuffix mat-icon-button aria-label="Clear">
            <span class="material-symbols-rounded"> search </span>
          </button>
        }
      </mat-form-field>
    </div>
  }
  
  <div class="c-table">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container *ngFor="let column of columns" [matColumnDef]="column">
          <th
            class="degrade-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ onCheckCol(column) }}
          </th>
          @if (column === 'info') {
            <td mat-cell *matCellDef="let row" (click)="onEmmitDetails(row, 'info')">
              <span class="material-symbols-rounded icon" title="Informações"> info </span>
            </td>
          } @else if (column === 'edit') {
            <td mat-cell *matCellDef="let row" (click)="onEmmitDetails(row, 'edit')">
              <span class="material-symbols-rounded icon" title="Editar"> edit </span>
            </td>
          } @else if (column === 'delete') {
            <td mat-cell *matCellDef="let row" (click)="onEmmitDetails(row, 'delete')">
              <span class="material-symbols-rounded icon" title="Remover"> delete </span>
            </td>
          } @else if (column === 'qtdgrupos') {
            <td mat-cell *matCellDef="let row">
              <div class="c-chip">
                {{ row[column] }}
              </div>
            </td>
          } @else if (column === 'qtdperguntas') {
            <td mat-cell *matCellDef="let row">
              <div class="c-chip">
                {{ row[column] }}
              </div>
          } @else {
            <td mat-cell *matCellDef="let row">
              {{ row[column] }}
            </td>
          }
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
        @if (activeFilter) {
          @if (textSearch) {
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [attr.colspan]="columns.length">
                <div class="c-empty-state">
                  <span class="material-symbols-rounded c-empty-state__icon">
                    filter_list_off
                  </span>
                  <span class="c-empty-state__message">
                    Não encontramos resultados para
                    <b>"{{ textSearch }}"</b>
                  </span>
                </div>
              </td>
            </tr>
          }
        }
        @if (dataSource.data.length < 1 && !isLoading.getValue()) {
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="columns.length">
              <div class="c-empty-state">
                <span class="material-symbols-rounded c-empty-state__icon">
                  filter_list_off
                </span>
                <span class="c-empty-state__message">
                  Não há registros para este item.
                </span>
              </div>
            </td>
          </tr>
        }
      </table>
    </div>
  </div>
  @if (dataSource.data.length < 1 && isLoading.getValue()) {
    <div class="c-skeleton-table">
      <!-- <mat-progress-bar mode="query"></mat-progress-bar> -->
      <div class="c-skeleton-table__row pulse"></div>
      <div class="c-skeleton-table__row pulse"></div>
      <div class="c-skeleton-table__row pulse"></div>
    </div>
  }
  @if (isPaginator) {
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
  }