import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import {
  MatPaginator,
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator'
import { MatSort, MatSortModule } from '@angular/material/sort'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { getPaginatorIntl } from '../../core/util/customPaginator'
import { BehaviorSubject } from 'rxjs'
import { TableColumnMapping } from '../../core/models/table-mapping.interface'

@Component({
  selector: 'app-table-generic',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    MatIconModule,
    MatProgressBarModule,
  ],
  providers: [{ provide: MatPaginatorIntl, useValue: getPaginatorIntl() }],
  templateUrl: './table-generic.component.html',
  styleUrl: './table-generic.component.scss'
})
export class TableGenericComponent {
  textSearch = ''

  @Input() activeFilter: boolean = true
  @Input() listDataSource: any[] = []
  @Input() columns: string[] = []
  @Input() columnMapping: TableColumnMapping = {}
  @Input() isPaginator: boolean = true
  @Input() isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true,
  )

  @Output() emmitDetails: EventEmitter<any> = new EventEmitter()

  dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.listDataSource,
  )

  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  constructor() {
    this.dataSource = new MatTableDataSource(this.listDataSource)
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.onResetPaginator()
    }, 1000)
  }

  onResetPaginator(): void {
    this.dataSource = new MatTableDataSource(this.listDataSource)

    if (this.dataSource) {
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value

    this.dataSource.filter = filterValue.trim().toLowerCase()

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  onCleanSearch(): void {
    this.textSearch = ''
    this.dataSource.filter = this.textSearch
  }

  onEmmitDetails(row: any, action: string): void {
    row.action = action;
    this.emmitDetails.emit(row)
  }

  onCheckCol(column: any): string{
    if(this.columnMapping[column]){
      return this.columnMapping[column]
    }else{
      return ''
    }
  }
}
